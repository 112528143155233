import { graphql } from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import { OpportunityPageContext } from "../../helpers/pagesContext";
import { CAREER } from "../../helpers/route";
import OpportunityScene from "../../scenes/Opportunity/Opportunity";

export default function Opportunity(props) {
  return (
    <Layout
      navbarTheme="dark"
      currentRoute={CAREER}
      data={props.data.contentfulMenuLabel}
    >
      <Seo
        title="Opportunités"
        description="Zenity recrute des candidats qualifiés dans les domaines de la qualité, du produit et du développement. Découvrez nos offres d'emploi et rejoignez notre équipe !"
      />
      <OpportunityPageContext.Provider
        value={props.data.contentfulOpportunityPage}
      >
        <OpportunityScene />
      </OpportunityPageContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($locale: String!) {
    contentfulOpportunityPage(node_locale: { eq: $locale }) {
      recrutment
      byZenity
      infoTitleOne
      infoSubTitleOne
      infoTitleTwo
      infoSubTitleTwo
      infoTitleThree
      infoSubTitleThree
      footerTitle
      node_locale
      id
      description
      opportunityList {
        url
        id
        image {
          id
          gatsbyImageData
        }
        teletravail
        poste
        localisation
      }
      subTitle
      title
      album {
        file {
          url
          fileName
          contentType
        }
        gatsbyImageData
      }
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;
