// extracted by mini-css-extract-plugin
export var asterisk = "Hero-module--asterisk--3f8dc";
export var blocTitle = "Hero-module--blocTitle--8752b";
export var overlay = "Hero-module--overlay--b20bd";
export var root = "Hero-module--root--773f5";
export var subRoot = "Hero-module--subRoot--39cee";
export var subTitle = "Hero-module--subTitle--e2ff2";
export var subTriangle = "Hero-module--subTriangle--8bf45";
export var title = "Hero-module--title--a8df8";
export var topTitle = "Hero-module--topTitle--182f4";
export var translate = "Hero-module--translate--79cfb";
export var triangle = "Hero-module--triangle--ebee9";
export var triangles = "Hero-module--triangles--d035b";
export var wrapperTitle = "Hero-module--wrapperTitle--26e05";