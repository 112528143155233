import React, { useContext } from "react";

import { Footer } from "../../components/Footer/Footer";
import { OpportunityPageContext } from "../../helpers/pagesContext";
import { Hero } from "./components/Hero/Hero";
import { Jobs } from "./components/Jobs/Jobs";
import * as styles from "./Opportunity.module.css";

export default function Opportunity() {
  const { album, subTitle, title, opportunityList, node_locale } = useContext(
    OpportunityPageContext,
  );

  return (
    <>
      <Hero title={title} subTitle={subTitle} album={album} main={true} />
      <Jobs opportunity={opportunityList} />
      <div className={styles.footer}>
        <Footer
          background={`linear-gradient(206.5deg, rgb(149 109 81 / 8%) -14.94%, rgb(42 59 62 / 48%) 88.71%)`}
          lang={node_locale}
        />
      </div>
    </>
  );
}
