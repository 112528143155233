import { useMediaQuery } from "@mui/material";
import { navigate } from "gatsby";
import React from "react";

import { localeLink } from "../../../../../helpers/localeLink";
import { OPPORTUNITY } from "../../../../../helpers/route";
import chevron from "../../../../../resources/images/opportunity-misc/chevron.svg";
import ping from "../../../../../resources/images/opportunity-misc/ping.svg";
import ping_light from "../../../../../resources/images/opportunity-misc/ping_light.svg";
import star from "../../../../../resources/images/opportunity-misc/star.png";
import * as styles from "./Job.module.css";

export const Job = ({ data }) => {
  const small = useMediaQuery("(max-width:767.98px)");

  return (
    <div
      className={styles.container}
      onClick={() => {
        navigate(localeLink(`${OPPORTUNITY}/${data.url}`));
      }}
    >
      <div className={styles.containerData}>
        <div className={styles.text}>
          <img
            className={styles.imageStar}
            src={star}
            alt="star"
            width={20}
            height={20}
          />
          <h3>{data.poste}</h3>
        </div>
        <div>
          <div className={styles.ping}>
            <img
              className={styles.pingIcone}
              src={small ? ping_light : ping}
              alt="ping"
            />
          </div>
          <div className={styles.localisation}>{data.localisation}</div>
        </div>
        <div>
          <div className={styles.arrow}>
            <img src={chevron} className={styles.arrowIcone} alt="chevron" />
          </div>
        </div>
      </div>
    </div>
  );
};
