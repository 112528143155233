import React from "react";

import * as styles from "./Triangle.module.css";

export const Triangle = () => {
  const svg = "M -14 43 C 30 -25 136 -27 182 43 L 549 691 H -11 Z";
  const stroke = "#D4A95E";
  const fill = "transparent";

  return (
    <section>
      <svg viewBox="0 -15 550 700">
        <path
          className={styles.fullTriangle}
          fill={fill}
          stroke={stroke}
          d={svg}
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </section>
  );
};
