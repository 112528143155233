import clsx from "clsx";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import triangle_1 from "../../../../resources/images/opportunity-misc/triangle_1.svg";
import triangle_2 from "../../../../resources/images/opportunity-misc/triangle_2.svg";
import triangle_3 from "../../../../resources/images/opportunity-misc/triangle_3.svg";
import * as styles from "./Hero.module.css";

export const Hero = ({ title, subTitle, album, main = false }) => {
  return main ? (
    <section className={styles.root}>
      <GatsbyImage image={album[0].gatsbyImageData} alt="main" />
      <GatsbyImage image={album[1].gatsbyImageData} alt="main" />
      <GatsbyImage image={album[2].gatsbyImageData} alt="main" />
      <div className={styles.triangles}>
        <img className={styles.triangle} src={triangle_1} alt="main" />
        <img className={styles.triangle} src={triangle_2} alt="main" />
        <img className={styles.triangle} src={triangle_3} alt="main" />
      </div>
      <div className={styles.blocTitle}>
        <div className={styles.topTitle}>
          <div className={styles.wrapperTitle}>
            <h1 className={styles.title}>{title}</h1>
            <span className={styles.asterisk}>*</span>
          </div>
        </div>
        <div className={styles.subTitle}>
          <span>*</span>
          {`${subTitle.split(",")[0]}`},<br />
          {`${subTitle.split(",")[1]}`}
        </div>
      </div>
      <span className={styles.overlay} />
    </section>
  ) : (
    <section className={clsx(styles.root, styles.subRoot)}>
      <img className={styles.subTriangle} src={triangle_2} alt="main" />
      <img className={styles.subTriangle} src={triangle_3} alt="main" />
      <span className={styles.overlay} />
    </section>
  );
};
