import clsx from "clsx";
import React, { useContext, useRef } from "react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { OpportunityPageContext } from "../../../../helpers/pagesContext";
import { useInView } from "../../../../helpers/useInView";
import { Triangle } from "../Triangle/Triangle";
import { Job } from "./components/Job";
import * as styles from "./Jobs.module.css";

const ANIMATION_START = 1;

export const Jobs = ({ opportunity }) => {
  const domRef = useRef();
  const inView = useInView(domRef, 250);
  const {
    recrutment,
    byZenity,
    infoTitleOne,
    infoSubTitleOne,
    infoTitleTwo,
    infoSubTitleTwo,
    infoTitleThree,
    infoSubTitleThree,
  } = useContext(OpportunityPageContext);

  return (
    <section className={styles.root}>
      <div className={styles.jobPresentation}>
        <div className={styles.title}>
          <h2>
            <strong>{recrutment}</strong>
          </h2>
          <span>{byZenity}</span>
        </div>
        <div className={styles.services}>
          <div className={clsx(styles.service, styles.serviceLeft)}>
            <strong>{infoTitleOne}</strong>
            <span>{infoSubTitleOne}</span>
          </div>
          <div className={styles.service}>
            <strong>{infoTitleTwo}</strong>
            <span>{infoSubTitleTwo}</span>
          </div>
          <div className={clsx(styles.service, styles.serviceRight)}>
            <strong>{infoTitleThree}</strong>
            <span>{infoSubTitleThree}</span>
          </div>
        </div>
      </div>
      {inView && (
        <div className={styles.triangleFrame}>
          <Triangle />
        </div>
      )}
      <div className={styles.opportunityContainer}>
        {opportunity.map((elem, index) => {
          return index === ANIMATION_START ? (
            <div ref={domRef} key={index}>
              <SlideTransition thresholds={100} delay={0.25 * index + 0.8}>
                <Job data={elem} />
              </SlideTransition>
            </div>
          ) : (
            <SlideTransition
              thresholds={100}
              delay={0.25 * index + 0.8}
              key={index}
            >
              <Job data={elem} />
            </SlideTransition>
          );
        })}
      </div>
    </section>
  );
};
