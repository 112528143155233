// extracted by mini-css-extract-plugin
export var jobPresentation = "Jobs-module--jobPresentation--0aefa";
export var opportunity = "Jobs-module--opportunity--9be52";
export var opportunityContainer = "Jobs-module--opportunityContainer--802c4";
export var root = "Jobs-module--root--45761";
export var service = "Jobs-module--service--c38d4";
export var serviceLeft = "Jobs-module--serviceLeft--eeb0f";
export var serviceRight = "Jobs-module--serviceRight--0964c";
export var services = "Jobs-module--services--06fc9";
export var title = "Jobs-module--title--4440d";
export var triangleFrame = "Jobs-module--triangleFrame--ec202";